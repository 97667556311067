import React from 'react';
import Navbar from "./nabvar";
import Footer from "./footer";

export default function Layout({children}) {
  return (
    <div>
      <Navbar/>
      {children}
      <Footer/>
    </div>
  );
}
