import React, {Fragment, useState, useDeferredValue} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Menu, Transition} from "@headlessui/react";
import {animateScroll, Link, scroller} from "react-scroll";
import {useScroll} from "@react-spring/web";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const [activeElement, setActiveElement] = useState("");
  const deferredActiveElement = useDeferredValue(activeElement)
  useScroll({
    onChange: ({value: {scrollYProgress}}) => {
      if (scrollYProgress > .99) {
        setActiveElement("contact")
      }
    }
  })

  return (
    <nav
      className=" w-full flex sm:flex-wrap md:justify-between md:items-center gap-10 px-10 lg:px-20 py-5 sticky top-0 z-30 bg-white drop-shadow-md">
      <div className="flex-1  flex text-center items-center justify-between">
        <Link
          onSetActive={setActiveElement}
          spy={true} smooth={true} duration={500} offset={-134}
          to="strength"
        >
          <a href="">
            <StaticImage src="../images/log2MainLogo.png" alt="Log2international logo"
                         style={{width: 200}}/>
          </a>
        </Link>
        <div>
          <Menu as="div" className="relative inline-block text-left lg:hidden">
            <div>
              <Menu.Button
                className="inline-flex  gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"></path>
                </svg>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({active}) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={() => scroller.scrollTo("mission", {smooth: true, duration: 500, offset: -134})}
                      >
                        OUR MISSION
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({active}) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={() => scroller.scrollTo("aboutus", {smooth: true, duration: 500, offset: -134})}
                      >
                        ABOUT US
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({active}) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                        onClick={() => scroller.scrollTo("values", {smooth: true, duration: 500, offset: -200})}
                      >
                        OUR VALUES
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({active}) => (
                      <button className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                              onClick={() => animateScroll.scrollToBottom({smooth: true, duration: 500, offset: -134})}
                      >
                        CONTACT
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="align-middle flex-2 hidden w-full lg:block md:w-auto">
        <ul
          className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white ">
          <li
            className={deferredActiveElement === "mission" ? "border-b-4 border-b-custom-orange hover:border-b-blue-700" : ""}>
            <Link
              onSetActive={setActiveElement}
              spy={true} smooth={true} duration={500} offset={-134}
              spyThrottle={500}
              to="mission"
              className="text-custom-orange text-xl block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
            >
              <a href="">
                OUR MISSION
              </a>
            </Link>
          </li>
          <li
            className={deferredActiveElement === "aboutus" ? "border-b-4 border-b-custom-orange hover:border-b-blue-700" : ""}>
            <Link
              onSetActive={setActiveElement}
              spy={true} smooth={true} duration={500} offset={-134}
              spyThrottle={500}
              to="aboutus"
              className=" text-custom-orange text-xl block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
            >
              <a href="">
                ABOUT US
              </a>
            </Link>
          </li>
          <li
            className={deferredActiveElement === "values" ? "border-b-4 border-b-custom-orange hover:border-b-blue-700" : ""}>
            <Link
              onSetActive={setActiveElement}
              spy={true} smooth={true} duration={500} offset={-134}
              spyThrottle={500}
              to="values"
              className=" text-custom-orange text-xl block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
            >
              <a href="">
                OUR VALUES
              </a>
            </Link>
          </li>
          <li
            className={deferredActiveElement === "contact" ? "border-b-4 border-b-custom-orange hover:border-b-blue-700" : ""}>
            <Link
              onSetActive={setActiveElement}
              spy={true} smooth={true} duration={500}
              spyThrottle={500}
              to="contact"
              className="text-custom-orange text-xl block py-2 pl-3 pr-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
            >
              <a href="" onClick={() => {
                animateScroll.scrollToBottom()
              }}>
                CONTACT
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
