import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {useInView, useSpring, animated} from "@react-spring/web";

export default function Footer() {
  const [ref, isInView] = useInView({once: true, amount: 200, threshold: 0.5})
  const [logoSpring] = useSpring(() => ({
    scale: isInView ? 1 : 0
  }), [isInView])
  const [addressSpring] = useSpring(() => ({
    x: isInView ? "0%" : "-100%",
    delay: 250
  }), [isInView])
  const [separatorSpring] = useSpring(() => ({
    scale: isInView ? 1 : 0,
    delay: 500
  }), [isInView])
  const [phoneSpring] = useSpring(() => ({
    x: isInView ? "0%" : "-100%",
    delay: 750
  }), [isInView])
  return (
    <div
      className="w-full flex flex-wrap h-auto justify-between gap-6 relative px-20 bg-[#062039] xl:h-[267px]"
      ref={ref}
    >
      <div className="flex flex-wrap lg:items-center justify-center gap-4 md:gap-10 py-10 ">
        <animated.div style={logoSpring}>
          <StaticImage src="../images/log2orangeConsulting.png" alt="Log2international logo white"
                       style={{maxWidth: 140}}/>
        </animated.div>
        <p className="text-lg md:text-xl text-white text-center lg:text-left font-paragraphs overflow-hidden">
          <animated.p style={addressSpring}>
            Adresse: Urb. La Plana núm.57 Ed.
            <br/>
            La Bohigueta Bloc A pis 2n 2a,
            <br/>
            AD700 Escaldes Engordany.
            <br/>
            Andorra Company Registration Number : L-712780-R
          </animated.p>
        </p>
        <animated.div
          style={separatorSpring}
          className="border-custom-orange md:border-l-4 md:border-t-0 border-t-8 lg:h-2/3  hidden xl:block"/>
        <p className="text-lg lg:text-xl text-center lg:text-left text-custom-orange font-paragraphs overflow-hidden">
          <animated.p style={phoneSpring}>
            Phone Number : +376 809655
            <br/>
            Mobile Number : +376 634302
            <br/>
            E-mail: <a href="mailto:contact@log2international.com">contact@log2international.com</a>
          </animated.p>

        </p>
      </div>
      <div className="absolute hidden xl:block  right-0 opacity-20 ">
        <StaticImage src="../images/patternWhiteBottom.png" alt="Log2international logo white" height={267}/>
      </div>
    </div>
  );
}
